import React from 'react';
import './styles/Formulary.scss';

const Formulary = () => {
    return(
        <div className="GoogleForm">
            <a href='http://form.karenspizza.com/' target="_blank" rel="noreferrer" className="Link"> Dejanos tu opinión </a>
        </div>
    )
}

export default Formulary