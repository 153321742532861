import React from 'react';
import './styles/Pedidos.scss';

const Pedidos = () => {
    

    return(
        <div className="containerP">
                <a className="pedidosButton" href={"https://www.karenspedidos.com"} target="_blank" rel="noreferrer">
                    Haz tu pedido
                </a>

        </div>
    )
}

export default Pedidos